<template>
    <div class="articleManager app-main-card">
        <a-table rowKey="key" :columns="columns" :data-source="data" :current="pageCurrent" :loading="loading">
            <a slot="articleTitle" slot-scope="articleTitle">{{ articleTitle }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> 标题</span>
            <span slot="articleImgUrl" slot-scope="articleImgUrl, record">
                <p><img class="articleCoverImg" :src="articleImgUrl" alt="" /></p>
                <a-upload
                    accept=".jpg,.png,.webp"
                    :data="{articleImgUrl, _id: record.key}"
                    name="files"
                    :multiple="true"
                    action="/root/changeImg"
                    @change="handleArticleImgChange"
                >
                    <a-button> <a-icon type="upload" /> Click to Upload </a-button>
                </a-upload>
            </span>
            <span slot="articleType" slot-scope="articleType">
                <a-tag
                    :color="articleType === ARTICLE_TYPE_MAP.bigImg ? 'volcano' : articleType === ARTICLE_TYPE_MAP.middleImg ? 'geekblue' : 'green'"
                >
                    {{ ARTICLE_TYPE_DESC_MAP[articleType] }}
                </a-tag>
            </span>
            <span slot="articleTop" slot-scope="articleTop, record">
                <a-switch default-checked :checked="articleTop" @change="onChangeArticleTop(articleTop, record)" />
            </span>
            <span slot="key" slot-scope="key">
                <a-space>
                    <a-button size="small" type="primary" @click="editArticleInfo(key)">修改信息</a-button>
                    <a-button size="small" type="dashed" @click="editArticleMD(key)">编辑文档</a-button>
                    <a-popconfirm v-if="data.length" title="Sure to delete?" @confirm="() => deleteArticle(key)">
                        <a-button size="small" type="danger">删除</a-button>
                    </a-popconfirm>
                </a-space>
            </span>
        </a-table>
    </div>
</template>
<script>
    import {ARTICLE_TYPE_MAP, ARTICLE_TYPE_DESC_MAP} from '@/lib/article_const'
    import {mapActions} from 'vuex';

    const columns = [
        {
            dataIndex: 'articleTitle',
            key: 'articleTitle',
            slots: {title: 'customTitle'},
            scopedSlots: {customRender: 'articleTitle'},
            width: 300,
            align: 'center'

        },
        {
            title: '描述',
            dataIndex: 'articleDesc',
            key: 'articleDesc',
            width: 300,
            align: 'center'


        },
        {
            title: '文章类型',
            dataIndex: 'articleType',
            key: 'articleType',
            scopedSlots: {customRender: 'articleType'},
            align: 'center'

        },
        {
            title: '封面',
            key: 'articleImgUrl',
            dataIndex: 'articleImgUrl',
            scopedSlots: {customRender: 'articleImgUrl'},
            align: 'center'
        },
        {
            title: '是否置顶',
            key: 'articleTop',
            dataIndex: 'articleTop',
            scopedSlots: {customRender: 'articleTop'},
            width: 200,
            align: 'center'

        },
        {
            title: '操作',
            key: 'key',
            dataIndex: 'key',
            scopedSlots: {customRender: 'key'},
            align: 'center'
        },
    ];

    const data = [
        {
            key: '1',
            articleTitle: 'John Brown',
            articleDesc: 32,
            articleImgUrl: '/articleCover/1647093503428QQ%E6%88%AA%E5%9B%BE20220312194114.png',
            articleType: 1,
            articleTop: false,

        },

    ];

    export default {
        name: 'ArticleManager',
        data() {
            return {
                data,
                columns,
                pageCurrent: 0,
                total: 0,
                pageSize: 10,
                ARTICLE_TYPE_MAP,
                ARTICLE_TYPE_DESC_MAP,
                loading: false,
                articleImgUrl: ''
            };
        },
        methods: {
            ...mapActions({
                vxGetArticleList: 'root/getArticleList', vxChangeArticleInfo: 'root/changeArticleInfo',
                vxDeleteArticle: 'root/deleteArticle'
            }),
            async getArticleList() {
                this.loading = true
                try {
                    const data = await this.vxGetArticleList({pageNum: this.pageCurrent, pageSize: this.pageSize});
                    console.log(data);

                    this.total = data.total
                    this.data = data.data.map((item, index) => {
                        return {
                            ...item,
                            key: item._id,
                            articleImgUrl: item.articleImgUrl
                        }

                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error('Internet Error!')
                }
                this.loading = false

            },
            editArticleInfo(key) {
                // console.log(key);
                this.$router.push({name: 'publish-article', query: {articleId: key}})

            },
            // 修改置顶
            async onChangeArticleTop(articleTop, record) {
                // console.log(key, record);
                const result = await this.vxChangeArticleInfo({id: record.key, articleTop: !articleTop})
                this.getArticleList()
            },
            // 修改封面
            handleArticleImgChange(info) {
                console.log(info);
                if (info.file.status === 'done') {
                    this.getArticleList()
                }
            },
            // 删除文章
            async deleteArticle(id) {
                await this.vxDeleteArticle({id})
                this.getArticleList()

            },
            // 编辑文档
            editArticleMD(key) {
                this.$router.push({name: 'update-article', query: {articleId: key}})
            }
        },
        mounted() {
            this.getArticleList()
        }
    };
</script>


<style lang="less" scoped>
    .articleManager {
        .articleCoverImg {
            height: 45px;
            margin-bottom: 5px;
        }
    }
</style>